// @flow
import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'src/components/layout'

type Props = {
  data: {
    asciidoc: {
      html: string,
    },
  },
  pageContext: {
    frontmatter: {
      title?: string,
      sidebar: false | string,
      meta: Array<any>,
    },
  },
}

const AsciiDocument = ({
  data: {
    asciidoc: { html },
  },
  pageContext: { frontmatter },
}: Props) => (
  <Layout frontmatter={frontmatter}>
    <div dangerouslySetInnerHTML={{ __html: html }} />
  </Layout>
)

export default AsciiDocument

export const pageQuery = graphql`
  query($id: String!) {
    asciidoc(id: { eq: $id }) {
      html
    }
  }
`
